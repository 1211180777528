var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ModaDialog',{attrs:{"item":_vm.activeItem},model:{value:(_vm.isModaDialog),callback:function ($$v) {_vm.isModaDialog=$$v},expression:"isModaDialog"}}),_c('SendMessageDialog',{attrs:{"item":_vm.activeItem},model:{value:(_vm.isSendMessageDialog),callback:function ($$v) {_vm.isSendMessageDialog=$$v},expression:"isSendMessageDialog"}}),_c('v-menu',{attrs:{"close-on-content-click":true,"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:{ hidden: !_vm.isActionsContextMenuAvailable(_vm.item) },attrs:{"icon":"","medium":"","disabled":!_vm.isActionsContextMenuAvailable(_vm.item)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{attrs:{"min-width":"198px"}},[_c('v-list',{attrs:{"dense":""}},[(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.openActionModal(_vm.item, _vm.TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Изменить ожидаемую дату")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.DONT_REMIND,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.openActionModal(_vm.item, _vm.TASKLIST_ACTION_TYPES.DONT_REMIND)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Не напоминаем")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.CONTACT,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.waBtnDisabled},on:{"click":function($event){return _vm.contact(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Связались")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.DONT_CONTACT,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.dontContactAction(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Не связались")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.REMIND,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.remindAction(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Напоминаем")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            project: _vm.project,
                            actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_COMMENT,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.openActionModal(_vm.item, _vm.TASKLIST_ACTION_TYPES.CHANGE_COMMENT)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Изменить комментарий")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            actionType: _vm.TASKLIST_ACTION_TYPES.CHANGE_MODA,
                            project: _vm.project,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled},on:{"click":function($event){return _vm.openModaDialog(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Настроить цикл возврата")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            actionType: _vm.TASKLIST_ACTION_TYPES.SEND_MESSAGE,
                            project: _vm.project,
                        })  && !_vm.findError(_vm.item)
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.waBtnDisabled},on:{"click":function($event){return _vm.openSendMessageDialog(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Отправка через бота")])]):_vm._e(),(
                        _vm.isActionAvailable({
                            item: _vm.item,
                            actionType: _vm.TASKLIST_ACTION_TYPES.MAKE_CALL,
                            project: _vm.project,
                            userId: _vm.userId,
                        })
                    )?_c('v-list-item',{staticClass:"text-btn-wrapper",attrs:{"disabled":_vm.buttonsDisabled || _vm.callActionDisabled},on:{"click":function($event){return _vm.makeCall(_vm.item)}}},[_c('span',{staticClass:"mx-3 my-2"},[_vm._v("Позвонить")])]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }