
import Vue, { PropType } from 'vue'
import { Project, Tag } from '@/types/main'
import _, { findIndex } from 'lodash'
import CardMenu from './CardMenu.vue'
import moment from 'moment'
import { checkRole } from '../../../../helpers/roles'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import { communicationUpdate } from '@root/src/api/chisai/GCB2'
import Loading from "@root/src/components/project/Loading.vue"

type FixActions = "updateCommunications"|"scanQR"

const errorBtnTextEnum : {
    [x in FixActions] : string
} = {
    "updateCommunications" : "Обновить расчёты",
    "scanQR" : "Сканировать QR-код"
}

export default Vue.extend({
    components: { CardMenu, Loading },
    props: {
        activationCardData: {
            type: Object,
            default: () => {},
        },
        activeCardsLimitExceeded: {
            type: Boolean,
            default: false,
        },
        cardsUpdatedTimestamp: {
            type: Number,
            default: 0,
        },
        tagsList: {
            type: Array as PropType<Tag[]>,
            required: true,
        },
        highlighted: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        loading : false,
        errors : []
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        hasEditRole(): boolean {
            return this.checkRole(this.project.id, 'gcb2:activation:edit')
        },
        hasRunningToogleRole(): boolean {
            return this.checkRole(this.project.id, 'gcb2:activation:toogleIsRunning')
        },
        hasDeleteRole(): boolean {
            return this.checkRole(this.project.id, 'gcb2:activation:delete')
        },
        disabled(): boolean {
            return this.activationCardData.disabled
        },
        displayAnalyticsData(): boolean {
            return (
                this.activationCardData.isRunning &&
                moment(this.activationCardData.isRunningChangedAt).valueOf() < this.cardsUpdatedTimestamp
            )
        },
        linkBtnText(): string {
            
            return this.displayAnalyticsData ? 'ПЕРЕЙТИ К СПИСКУ' : 'Требуется обновление'
        },
        priorityPanelLabel(): string {
            return this.activationCardData.isRunning ? 'ПРИОРИТЕТ' : ''
        },
        priorityPanelLabelCount(): string {
            return this.activationCardData.isRunning ? this.activationCardData.priority : ''
        },
        tags(): Tag[] {
            return this.tagsList.filter(tag => {
                if (this.activationCardData.isAutomatic && tag.id === '_automatic') return true
                if (!this.activationCardData.isAutomatic && tag.id === '_manual') return true
                if (this.activationCardData.tags.includes(tag.id)) {
                    return true
                }
                return false
            })
        },
        showTags(): boolean {
            const GCB2Connector = this.project.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            return !GCB2Connector?.overviewSettings?.hideTags
        },
        isError(): boolean {
            return Boolean(this.errors.length)
        },
        sortedByPriorityErrors() {
            return this.errors.sort((a,b) => a.priority - b.priority)
        }
    },
    methods: {
        checkRole,
        getPriorityPanelOnClick(type: 'inc' | 'dec') {
            return this.activationCardData.isRunning
                ? this.changePriority(this.activationCardData.activationId, type)
                : this.changeOrder(this.activationCardData.activationId, type)
        },
        changePriority(activationId: number, type: 'inc' | 'dec') {
            this.$emit('change-priority', { activationId, type })
        },
        changeOrder(activationId: number, type: 'inc' | 'dec') {
            this.$emit('change-order', { activationId, type })
        },
        changeIsRunning({ activationId, value }: { activationId: number; value: boolean }) {
            this.$emit('change-is-running', { activationId, value })
        },
        deleteActivation(activationId: number, value: boolean) {
            this.$emit('delete-activation', activationId)
        },
        copyActivation(activationId: number) {
            this.$emit('copy-activation', activationId)
        },
        goToTaskList(activationId: number) {
            this.$router.push(
                `${this.$router.currentRoute.path}?section=taskList&activations=${activationId}`
            )
        },
        async fixProblem(action : FixActions) {
            console.log(this.$router, action)
            this.loading = true
            switch (action) {
                case "updateCommunications" : {
                    await communicationUpdate({ projectId: this.project.id })
                    break
                }
            }
            this.errors = this.errors.filter(error => error.action !== action)
            this.loading = false
        },
    },

    mounted() {},
})
