import { Vue } from 'vue-property-decorator'
import { TASKLIST_ACTION_TYPES, TASKLIST_STATUSES } from '../../../../vars/GCB2'
import * as t from '@/store/chisai/GCB2/actionTypes'
import { Project } from '../../../../types/main'
import { CONNECTOR_TYPES, TELEPHONY_CONNECTOR_TYPES } from '../../../../vars/general'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import _ from 'lodash'
import websocket from '../../../../websockets'
import { makeCall as makeCallMG } from '../../../../api/connectors/telephonyMegaphone'
import { makeCall as makeCallPZ } from '../../../../api/connectors/telephonyProstyiZvonki'
import { makeCall as makeCallSipuni } from '../../../../api/connectors/sipuni'
import { checkRole } from '../../../../helpers/roles'
import { ManualCommunication } from '../../../../types/GCB2'
import { setSnackBarNotification } from '../../../../api/notification'
import { ImportantError } from '../../../../helpers/Error'

import { callNow as makeCallPbx } from '@api/connectors/pbxOnline'

import makeCallWrapper, { Telephonyes } from '@root/src/api/connectors/makeCallWrapper'

export interface TasklistContactActionParams {
    vueInstanse: Vue
    item: any
    textMessage?: string
    comment?: string
    communicationTags?: string[]
}
export interface TasklistDontRemindActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
}
export interface TasklistChangeCommentActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
}
export interface TasklistChangeExpectedDateActionParams {
    vueInstanse: Vue
    item: any
    comment: string
    communicationTags: string[]
    postponedTo: string
}

const beforeEach = (vueInstanse: Vue, item: any) => {
    vueInstanse.$store.commit(`GCB2/${t.ADD_LOADING_COMMUNNICATION_IDS}`, [item.communicationId])
}
const afterEach = (vueInstanse: Vue, item: any) => {
    vueInstanse.$store.commit(`GCB2/${t.REMOVE_LOADING_COMMUNNICATION_IDS}`, [item.communicationId])
}
const batchBeforeEach = (vueInstanse: Vue, actionData: any) => {
    vueInstanse.$store.commit(
        `GCB2/${t.ADD_LOADING_COMMUNNICATION_IDS}`,
        _.map(actionData, 'communicationId')
    )
}
const batchAfterEach = (vueInstanse: Vue, actionData: any) => {
    vueInstanse.$store.commit(
        `GCB2/${t.REMOVE_LOADING_COMMUNNICATION_IDS}`,
        _.map(actionData, 'communicationId')
    )
}
const onError = (vueInstanse: Vue, item: ManualCommunication | null) => {
    //const gcb2Store = useGcb2Store()
    //gcb2Store.setTasklistErrorModalIsActive(true)
    if (item) {
        vueInstanse.$store.commit(`GCB2/${t.TASKLIST_BATCH_CHANGE_ITEM}`, [
            {
                communicationId: item.communicationId,
                fields: {
                    color: null,
                },
            },
        ])
    }
}
const onErrorBatch = (vueInstanse: Vue, items: any[] | null) => {
    //const gcb2Store = useGcb2Store()
    //gcb2Store.setTasklistErrorModalIsActive(true)
    if (items) {
        vueInstanse.$store.commit(
            `GCB2/${t.TASKLIST_BATCH_CHANGE_ITEM}`,
            items.map(item => ({
                communicationId: item.communicationId,
                fields: {
                    color: null,
                },
            }))
        )
    }
}

export default {
    isActionAvailable: ({ item, actionType, project, userId }: any) => {
        switch (actionType) {
            case TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                ].includes(item.status)
            }

            case TASKLIST_ACTION_TYPES.CONTACT: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                ].includes(item.status)
            }

            case TASKLIST_ACTION_TYPES.DONT_CONTACT: {
                return (
                    [TASKLIST_STATUSES.CONTACTED_AND_DONT_COME].includes(item.status) &&
                    checkRole(project.id, 'gcb2:tasklist:action:dontContact:view')
                )
            }
            case TASKLIST_ACTION_TYPES.DONT_REMIND: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.CONTACTED_AND_COME,
                    TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                ].includes(item.status)
            }
            case TASKLIST_ACTION_TYPES.REMIND: {
                return [TASKLIST_STATUSES.DONT_REMIND, TASKLIST_STATUSES.DONT_REMIND_SERVICE].includes(
                    item.status
                )
            }
            case TASKLIST_ACTION_TYPES.CHANGE_COMMENT: {
                return [
                    TASKLIST_STATUSES.NEED_TO_CONTACT,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                    TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                    TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                    TASKLIST_STATUSES.CONTACTED_AND_COME,
                    TASKLIST_STATUSES.DONT_REMIND,
                ].includes(item.status)
            }
            case TASKLIST_ACTION_TYPES.CHANGE_MODA: {
                return (
                    [
                        TASKLIST_STATUSES.NEED_TO_CONTACT,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                        TASKLIST_STATUSES.POSTPONED_COMMUNICATION,
                        TASKLIST_STATUSES.CONTACTED_AND_DONT_COME,
                        TASKLIST_STATUSES.CONTACTED_AND_COME,
                    ].includes(item.status) &&
                    (checkRole(project.id, 'gcb2:tasklist:moda:general:view') ||
                        checkRole(project.id, 'gcb2:tasklist:moda:personal:view'))
                )
            }

            case TASKLIST_ACTION_TYPES.SEND_MESSAGE: {
                const hasYClientsConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.yClients
                )
                const hasWhatsappConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.whatsapp
                )
                const hasWhatsappBasisConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.whatsappBasis
                )
                const hasAltegioConnector = project?.connectors.some(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.altegio
                )
                const hasYCHrefInfo = item?.hrefInfo?.find(
                    (el: any) => el.connectorType === CONNECTOR_TYPES.yClients
                )
                const hasTelHrefInfo = item?.hrefInfo?.find((el: any) => el.connectorType === 'tel')
                return (
                    [
                        TASKLIST_STATUSES.NEED_TO_CONTACT,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_TODAY,
                        TASKLIST_STATUSES.NEED_TO_CONTACT_YESTERDAY,
                    ].includes(item.status) &&
                    ((hasYClientsConnector && hasYCHrefInfo) ||
                        (hasWhatsappConnector && hasTelHrefInfo) ||
                        (hasAltegioConnector && hasTelHrefInfo) ||
                        (hasWhatsappBasisConnector && hasTelHrefInfo))
                )
            }

            case TASKLIST_ACTION_TYPES.MAKE_CALL: {
                const hasTelHrefInfo = item?.hrefInfo?.find((el: any) => el.connectorType === 'tel')
                const telephonyConnector = project.connectors.find((el: any) =>
                    TELEPHONY_CONNECTOR_TYPES.filter(
                        type =>
                            type !== CONNECTOR_TYPES.telephonyBeeline &&
                            type !== CONNECTOR_TYPES.telephonyMango
                    ).includes(el.connectorType)
                )
                const hasUserMapping = telephonyConnector?.usersMapping?.[userId]
                return telephonyConnector && hasUserMapping && hasTelHrefInfo
            }
            default:
                return false
        }
    },
    contact: async ({
        vueInstanse,
        item,
        textMessage,
        comment,
        communicationTags,
    }: TasklistContactActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })

            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CONTACT,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    clientId: item.clientId,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    textMessage: textMessage,
                    comment,
                    communicationTags,
                },
            })

            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CONTACT', item },
                originalError: err,
            })
        }
    },
    dontContact: async (vueInstanse: Vue, item: any) => {
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_CONTACT,
                communicationId: item.communicationId,
                statusText: item.status,
                messageError: false,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_CONTACT', item },
                originalError: err,
            })
        }
    },
    dontRemind: async ({ vueInstanse, item, comment, communicationTags }: TasklistDontRemindActionParams) => {
        const gcb2Store = useGcb2Store()
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_REMIND,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    communicationId: item.communicationId,
                    projectId: item.projectId,
                    clientId: item.clientId,
                    comment: comment,
                    userId: user.sub,
                    communicationTags,
                },
            })
            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_REMIND', item },
                originalError: err,
            })
        }
    },
    dontRemindService: async (vueInstanse: Vue, projectId: string, diff: any) => {
        const gcb2Store = useGcb2Store()
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                actionType: TASKLIST_ACTION_TYPES.DONT_REMIND_SERVICE,
                communicationId: null,
                socketId,
                actionData: {
                    projectId: projectId,
                    diff,
                    userId: user.sub,
                },
            })
        } catch (err) {
            onError(vueInstanse, null)
            throw new ImportantError('tasklist action', {
                payload: { action: 'DONT_REMIND_SERVICE' },
                originalError: err,
            })
        }
    },
    remind: async (vueInstanse: Vue, item: any) => {
        beforeEach(vueInstanse, item)
        try {
            const socketId = websocket.socket.id
            const _this = vueInstanse
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.REMIND,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    communicationId: item.communicationId,
                    projectId: item.projectId,
                    clientId: item.clientId,
                    serviceId: item.serviceId,
                },
            })
            setSnackBarNotification({
                projectId: item.projectId,
                active: true,
                type: 'GCB2',
                name: 'newData',
                text: 'Загружены новые данные. Обновите расчёты, чтобы увидеть изменения.',
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'REMIND', item },
                originalError: err,
            })
        }
    },
    changeComment: async ({
        vueInstanse,
        item,
        comment,
        communicationTags,
    }: TasklistChangeCommentActionParams) => {
        try {
            const socketId = websocket.socket.id
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_COMMENT,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    comment: comment,
                    communicationTags,
                    userId: user.sub,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_COMMENT', item },
                originalError: err,
            })
        }
    },
    changeExpectedDate: async ({
        vueInstanse,
        item,
        comment,
        postponedTo,
        communicationTags,
    }: TasklistChangeExpectedDateActionParams) => {
        try {
            const socketId = websocket.socket.id
            const gcb2Store = useGcb2Store()
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            const prevStatus = item.status
            const currentIndex = _this.$store.getters[`GCB2/${t.GET_CURRENT_COMMUNICATION_INDEX}`]({
                projectId: item.projectId,
                communicationId: item.communicationId,
                status: item.status,
            })
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    postponedTo,
                    userId: user.sub,
                    userName: user.name,
                    email: user.email,
                    comment: comment,
                    communicationTags,
                    clientId: item.clientId,
                },
            })
            const nextManualCommunication = _this.$store.getters[
                `GCB2/${t.GET_COMMUNICATION_BY_CURRENT_INDEX}`
            ]({
                projectId: item.projectId,
                index: currentIndex,
                status: prevStatus,
            })
            if (nextManualCommunication) {
                gcb2Store.openSidebar({
                    type: 'tasklist',
                    item: nextManualCommunication,
                    section: 'clientInfo',
                    communicationId: nextManualCommunication.communicationId,
                })
            }
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_EXPECTED_DATE', item },
                originalError: err,
            })
        }
    },
    changeTextMessage: async (vueInstanse: Vue, item: any, textMessage: string) => {
        try {
            const socketId = websocket.socket.id
            beforeEach(vueInstanse, item)
            const _this = vueInstanse
            const user = _this.$store.getters.myUser
            _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: item.projectId,
                actionType: TASKLIST_ACTION_TYPES.CHANGE_TEXT_MESSAGE,
                communicationId: item.communicationId,
                statusText: item.status,
                socketId,
                actionData: {
                    projectId: item.projectId,
                    communicationId: item.communicationId,
                    textMessage: textMessage,
                },
            })
            afterEach(vueInstanse, item)
        } catch (err) {
            afterEach(vueInstanse, item)
            onError(vueInstanse, item)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_TEXT_MESSSAGE', item },
                originalError: err,
            })
        }
    },
    batchChangeTextMessage: async (
        vueInstanse: Vue,
        projectId: string,
        data: {
            projectId: string
            communicationId: string
            textMessage: string
        }[]
    ) => {
        try {
            const socketId = websocket.socket.id
            batchBeforeEach(vueInstanse, data)
            const _this = vueInstanse
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                socketId,
                actionType: TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE,
                actionData: data,
            })
            batchAfterEach(vueInstanse, data)
        } catch (err) {
            batchAfterEach(vueInstanse, data)
            onErrorBatch(vueInstanse, data)
            throw new ImportantError('tasklist action', {
                payload: { action: 'CHANGE_TEXT_MESSSAGE' },
                originalError: err,
            })
        }
    },
    batchContact: async (
        vueInstanse: Vue,
        projectId: string,
        data: {
            projectId: string
            communicationId: string
            clientId: string
            userId: string
            userName: string
            email: string
            textMessage: string
        }[]
    ) => {
        try {
            const socketId = websocket.socket.id
            batchBeforeEach(vueInstanse, data)
            const _this = vueInstanse
            await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
                projectId: projectId,
                socketId,
                actionType: TASKLIST_ACTION_TYPES.BATCH_CONTACT,
                actionData: data,
            })
            batchAfterEach(vueInstanse, data)
        } catch (err) {
            batchAfterEach(vueInstanse, data)
            onErrorBatch(vueInstanse, data)
            throw new ImportantError('tasklist action', {
                payload: { action: 'BATCH_CONTACT', dataLength: data.length },
                originalError: err,
            })
        }
    },
    websocketAction: async (vueInstanse: Vue, payload: any) => {
        const [beforeEachFunc, afterEachFunc] = [
            TASKLIST_ACTION_TYPES.BATCH_CHANGE_TEXT_MESSAGE,
            TASKLIST_ACTION_TYPES.BATCH_CONTACT,
        ].includes(payload.actionType)
            ? [beforeEach, afterEach]
            : [batchBeforeEach, batchAfterEach]
        beforeEachFunc(vueInstanse, payload.actionData)
        const _this = vueInstanse
        await _this.$store.dispatch(`GCB2/${t.MAKE_TASKLIST_ACTION}`, {
            projectId: payload.projectId,
            actionType: payload.actionType,
            actionData: payload.actionData,
            communicationId: payload.communicationId,
            responseData: payload.responseData,
        })
        afterEachFunc(vueInstanse, payload.actionData)
    },
    makeCall: async ({ vueInstanse, item, project }: { vueInstanse: Vue; item: any; project: Project }) => {
        try {
            vueInstanse.$store.commit(`GCB2/${t.ADD_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                item.communicationId,
            ])
            const phone = item.hrefInfo
                .find((el: any) => el.connectorType === 'tel')
                ?.phone.replace(/\D/g, '')
            const telephonyConnector = project.connectors.find((el: any) =>
                TELEPHONY_CONNECTOR_TYPES.includes(el.connectorType)
            )

            let makeCall
            const isPbxOnline = telephonyConnector?.connectorType === CONNECTOR_TYPES.pbxOnline

            if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyMegaphone) {
                makeCall = makeCallMG
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyProstyiZvonki) {
                makeCall = makeCallPZ
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.sipuni) {
                makeCall = makeCallSipuni
            } else if (isPbxOnline) {
                makeCall = makeCallPbx
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyBeeline) {
                makeCall = makeCallWrapper(Telephonyes.Beeline)
            } else if (telephonyConnector?.connectorType === CONNECTOR_TYPES.telephonyMango) {
                makeCall = makeCallWrapper(Telephonyes.Mango)
            }

            if (!makeCall) {
                throw new ImportantError('Telephony makeCall method not found')
            }
            setTimeout(() => {
                vueInstanse.$store.commit(`GCB2/${t.REMOVE_DISABLED_CALL_COMMUNNICATION_IDS}`, [
                    item.communicationId,
                ])
            }, 30 * 1000)
            if (!isPbxOnline) {
                const { data, error } = await makeCall({
                    connectorId: telephonyConnector!.connectorId,
                    phone,
                    communicationId: item.communicationId,
                })
                if (error) {
                    onError(vueInstanse, null)
                }
            }
            if (isPbxOnline) {
                const userId = vueInstanse.$store.getters.myUser.sub

                const { data, error } = await makeCall({
                    connectorId: telephonyConnector?.connectorId,
                    from: telephonyConnector?.usersMapping?.[userId],
                    to: phone,
                    domain: telephonyConnector?.domain,
                })
                if (error) {
                    onError(vueInstanse, null)
                }
            }
        } catch (err) {
            onError(vueInstanse, null)
            throw new ImportantError('tasklist action', {
                payload: { action: 'MAKE_CALL', item },
                originalError: err,
            })
        }
    },
}
