import { getInstance } from '@root/src/auth'
import { ApiWrapper } from '../../types/main'
import { apiCall, http } from '../http'
import cfg from '@root/config'
import { getError } from '../../utils'

interface InputData {
    connectorId: string
    phone: string
    userId?: string
    userVirtualATC?: string
    communicationId?: string
}

export enum Telephonyes {
    Beeline = "beeline",
    Mango = "mango"
}

export default async (telephony : Telephonyes) => {
    return async function makeCall({
        connectorId,
        phone,
        userId,
        userVirtualATC,
        communicationId,
    }: InputData) : Promise<ApiWrapper<any | null>> {
        const auth = getInstance()
        const token = await auth.getTokenSilently()
        // Поменял на beeline
        const url = `${cfg.apiEndpoint}/ipTelephony/${telephony}/makeCall`
        const options = {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                connectorId,
                phone,
                userId: userId ?? auth.user.sub,
                userVirtualATC,
                communicationId,
            }),
        }
        const res = await fetch(url.toString(), options)
        const error = await getError(res)
        const data = await res.json()
        return { data, error: error }
    }
}