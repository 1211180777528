import * as _ from 'lodash'
import { Activation, Message, EditedActivation } from '../types/GCB2'
import api from '../api/api'
import { Project } from '../types/main'
import messages from '@dataheroes/messages'
const DEFAULT_AUTOMAIL_SETTINGS = {
    limitOneMailing: 10,
    commonLimit: 50,
    intervalInMinutes: 15,
    sendAfter: '',
    sendBefore: '',
    timezoneOffset: null,
    isDeleted: true,
    sendAfterDate: null,
    sendBeforeDate: null,
    stopOnBan: false,
}
const DEFAULT_BATCH_MODE_SETTINGS = {
    timezone_offset: '3',
    default_daily_limit_of_messages_items: '100',
}

interface GetInitedCreatedActivationParams {
    runningActivationsCount: number
    runningActivationsLimit: number
    projectId: string
}
interface GetInitedUpdatedActivationParams {
    activationsList?: Activation[]
    activationId: number
    projectId: string
}
export const editActivationService = {
    getInitedCreatedActivation,
    getInitedUpdatedActivation,
    createActivation,
    updateActivation,
    copyActivation,
}

function getInitedCreatedActivation({
    runningActivationsCount,
    runningActivationsLimit,
    projectId,
}: GetInitedCreatedActivationParams) {
    return {
        projectId,
        isRunning: runningActivationsCount < runningActivationsLimit ? true : false,
        name: '',
        description: '',
        group: '',
        audienceId: null,
        extra: {
            automail: Object.assign({}, DEFAULT_AUTOMAIL_SETTINGS),
        },
        tags: [],
        communicationTags: [],
        batchModeSettings: _.merge({}, DEFAULT_BATCH_MODE_SETTINGS),
        message: {
            id: null,
            name: '',
            group: '',
            messageParts: [
                {
                    partNumber: 1,
                    textVariant: '',
                    _id: _.uniqueId(),
                },
            ],
        },
    }
}
async function getInitedUpdatedActivation({
    activationId,
    activationsList,
    projectId,
}: GetInitedUpdatedActivationParams) {
    const activation = activationsList
        ? activationsList.find(el => el.id === activationId)
        : await api.gcb2.getActivation({ projectId: projectId, id: activationId }).then(res => res.data)

    const message: Message = await api.gcb2.getMessage({
        id: activation!.messageId,
        projectId: projectId,
    })
    const extraClone = _.cloneDeep(activation?.extra ?? {})
    extraClone.automail = _.merge({}, DEFAULT_AUTOMAIL_SETTINGS, extraClone?.automail)
    return {
        id: activation!.id,
        projectId: activation!.projectId,
        name: activation!.name,
        group: activation!.group || '',
        description: activation!.description || '',
        isRunning: activation!.isRunning,
        audienceId: activation!.audienceId,
        extra: extraClone,
        tags: activation!.tags ?? [],
        communicationTags: [],
        batchModeSettings: _.merge({}, DEFAULT_BATCH_MODE_SETTINGS),
        mode : activation.mode,
        testModePhone : activation.testModePhone,
        message: {
            id: message.id,
            name: message.name,
            group: message.group,
            messageParts: message.messageParts.map(el => ({
                id: el.id,
                _id: _.uniqueId(),
                partNumber: el.partNumber,
                textVariant: el.textVariant,
            })),
        },
    }
}
async function saveMessageVars(activation: EditedActivation) {
    if (!activation.messageVars) return
    return Promise.all([
        api.gcb2.updateDefaultMessageVarsValues({
            projectId: activation.projectId,
            messageVars: activation.messageVars.updated.filter(el => el.isDefault),
        }),
        Promise.all([
            activation.messageVars.updated
                .filter(el => !el.isDefault)
                .map(el =>
                    api.gcb2.updateMessageVarExeption({
                        projectId: activation.projectId,
                        value: el.value,
                        varId: el.varId,
                        clientId: el.id as string,
                    })
                ),
        ]),
        Promise.all([
            activation.messageVars.deleted.map(el =>
                api.gcb2.deleteMessageVarExeption({
                    projectId: activation.projectId,
                    clientId: el.id as string,
                    varId: el.varId,
                })
            ),
        ]),
    ])
}

async function saveBatchModeSettings(activation: EditedActivation, project: Project) {
    const messagingConnector = messages.getMessagingConnector(project.connectors)
    if (!messagingConnector) return
    await api.massender.saveLimitSettings({
        projectId: activation.projectId,
        connectorType: messagingConnector.connectorType,
        settings: activation.batchModeSettings!,
    })
}
async function saveCommunicationTags(activation: EditedActivation) {
    await api.gcb2.saveCommunicationTags({
        projectId: activation.projectId,
        tags: activation.communicationTags.map(tag => ({ ...tag, name: tag.name.trim() })),
    })
}
function mapActivationExtraBeforeSave(extra: any) {
    const extraCopy = _.cloneDeep(extra)
    if (extraCopy?.automail?.intervalInMinutes < 15) {
        extraCopy.automail.intervalInMinutes = 15
    }
    return extraCopy
}

async function createActivation(activation: EditedActivation, project: Project, isCopy?: boolean) {
    const createdMessage = await api.gcb2.createMessage({
        projectId: activation.projectId,
        name: activation.message.name,
        group: activation.message.group,
    })

    await Promise.all(
        activation.message.messageParts
            .filter((messagePart: any) => !messagePart._deleted)
            .map((messagePart: any) =>
                api.gcb2.createMessagePart({
                    projectId: activation.projectId,
                    messageId: createdMessage.data?.id as number,
                    partNumber: messagePart.partNumber,
                    textVariant: messagePart.textVariant,
                })
            )
    )
    
    const createdActivation = await api.gcb2
        .createActivation({
            projectId: activation.projectId,
            name: activation.name,
            group: activation.group,
            messageId: createdMessage.data?.id as number,
            description: activation.description,
            audienceId: activation.audienceId as number,
            isRunning: activation.isRunning,
            extra: mapActivationExtraBeforeSave(activation.extra),
            tags: activation.tags,
            mode : activation.mode,
            testModePhone : activation.testModePhone
        })
        .then(res => res.data)
    activation.id = createdActivation!.id

    const promises: Promise<any>[] = []
    if (!isCopy) promises.push(saveMessageVars(activation))
    if (!isCopy) promises.push(saveCommunicationTags(activation))
    if (!isCopy) promises.push(saveBatchModeSettings(activation, project))
    await Promise.all(promises)
    return createdActivation
}
async function updateActivation(activation: EditedActivation, project: Project) {
    const updatedMessage = await api.gcb2.updateMessage({
        id: activation.message.id as number,
        projectId: activation.projectId,
        name: activation.message.name,
        group: activation.message.group,
    })
    if (activation['media']?.src) {
        await api.gcb2.setActivationMedia({
            id: activation.id,
            projectId: project.id,
            mimeType: activation['media'].mimeType,
            src: activation['media'].src,
            originalName: activation['media'].originalName,
        })
    }
    if (!activation['media']?.src) {
        await api.gcb2.delActivationMedia({
            id: activation.id,
            projectId: project.id,
        })
    }

    await Promise.all(
        activation.message.messageParts.map(messagePart => {
            if (messagePart.id && messagePart._deleted) {
                api.gcb2.deleteMessagePart({
                    id: messagePart.id as number,
                    projectId: activation.projectId,
                })
            } else if (messagePart.id) {
                api.gcb2.updateMessagePart({
                    id: messagePart.id as number,
                    projectId: activation.projectId,
                    partNumber: messagePart.partNumber,
                    textVariant: messagePart.textVariant,
                })
            } else {
                api.gcb2.createMessagePart({
                    messageId: updatedMessage.data?.id as number,
                    projectId: activation.projectId,
                    partNumber: messagePart.partNumber,
                    textVariant: messagePart.textVariant,
                })
            }
        })
    )

    const updatedActivation = await api.gcb2
        .updateActivation({
            id: activation.id as number,
            projectId: activation.projectId,
            name: activation.name,
            group: activation.group,
            messageId: activation.message.id as number,
            description: activation.description,
            audienceId: activation.audienceId as number,
            isRunning: activation.isRunning,
            extra: mapActivationExtraBeforeSave(activation.extra),
            tags: activation.tags,
            mode : activation.mode,
            testModePhone : activation.testModePhone
        })
        .then(res => res.data)

    await Promise.all([
        saveMessageVars(activation),
        saveCommunicationTags(activation),
        saveBatchModeSettings(activation, project),
    ])
}
async function copyActivation(projectId: string, project: Project, activationId: number) {
    const activation = await getInitedUpdatedActivation({
        projectId,
        activationId,
    })
    activation.isRunning = false
    const createdActivation = await createActivation(activation, project, true)
    return createdActivation
}
