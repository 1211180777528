
import Vue from 'vue'

export default Vue.extend({
    data: () => ({
        steps: [
            {
                target: '#reloadBtn',
                content: 'Жать сюда',
                enableScrolling: false,
                params: {
                    highlightClass: 'custom-highlight',
                    highlight: true,
                    placement: 'bottom',
                    modifiers: [
                        //from https://popper.js.org/
                        {
                            name: 'offset',
                            options: {
                                offset: [-180, 20],
                            },
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: true,
                                altAxis: true,
                            },
                        },
                    ],
                },
            },
        ],
    }),
})
